import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import { NEEDS_DASHBOARD_ENDPOINTS } from '@screens/NeedsDashboard/Root/components/Routing/endpoints';
import { DATE_FORMAT } from '@helpers/date.helper';
import Caption1 from '@components/NewDesign/Typography/Caption/Caption1';
import Caption3 from '@components/NewDesign/Typography/Caption/Caption3';
import { IBookingDetailsForSchedulingDto } from '@screens/NeedsDashboard/Bookings/model/BookingDetailsDto';
import { IBindingCallback1 } from '@models/Callbacks';
import { SelectBookingButtonWithModal } from '../SelectBookingButtonWithModal';
import styles from './styles.module.scss';

interface IBookingSelectProps {
  bookings: IBookingDetailsForSchedulingDto[];
  locationBookingId: string | undefined;
  selectedBooking: IBookingDetailsForSchedulingDto | null;
  onBookingSelect: IBindingCallback1<IBookingDetailsForSchedulingDto>;
}

const BookingSelect = ({ bookings, locationBookingId, selectedBooking, onBookingSelect }: IBookingSelectProps) => {
  useEffect(() => {
    if (selectedBooking?.booking?.id || !bookings?.length) {
      return;
    }

    if ((bookings?.length === 1) || (bookings?.length >= 2 && !locationBookingId)) {
      onBookingSelect(bookings[0]);
      return;
    }

    const bookingFoundByLocationBookingId = bookings
      .find(bookingDetails => bookingDetails?.booking?.id === locationBookingId);

    if (bookingFoundByLocationBookingId) {
      onBookingSelect(bookingFoundByLocationBookingId);
    } else {
      onBookingSelect(bookings[0]);
    }
  }, [bookings, locationBookingId, onBookingSelect, selectedBooking]);

  const booking = selectedBooking?.booking ?? {};

  const handleSelectBooking = useCallback((bookingDetails: IBookingDetailsForSchedulingDto) => {
    onBookingSelect(bookingDetails);
  }, [onBookingSelect]);

  return (
    <div>
      <BookingCard booking={booking} onSelectBooking={handleSelectBooking} />
    </div>
  );
};

const BookingCard = ({ booking, onSelectBooking }) => (
  <div className={styles.container}>
    <div className={styles.info_group}>
      <div className={styles.top_row}>
        <Caption1>Additional services will be linked to your active booking</Caption1>
      </div>
      <div className={styles.dates}>
        <Caption3>
          Booked from&nbsp;
          {moment(booking.startingDate).format(DATE_FORMAT)}
          {booking.endingDate && ` to ${moment(booking.endingDate).format(DATE_FORMAT)}`}
        </Caption3>
      </div>
    </div>
    <div className={styles.actions_group}>
      <SmallLinkButton
        to={NEEDS_DASHBOARD_ENDPOINTS.BOOKING_DETAILS(booking?.id)}
        className={styles.open_booking_button}
      >
        Open Booking
      </SmallLinkButton>
      <SelectBookingButtonWithModal
        selectedBookingId={booking?.id}
        onSelectBooking={onSelectBooking}
      />
    </div>
  </div>
);

export { BookingSelect };
