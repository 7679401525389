import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Divider } from 'semantic-ui-react';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { ISpaceTemplateDto } from '@screens/BookingCheckout/model/PaymentRequirementResponse';
import {
  extractChosenSpace,
  extractFetchSpaceDetailsLoading,
  extractFetchSpacesBySpaceTemplateLoading,
  extractSpaces,
  extractSpaceTemplate,
  extractSaveSpaceLoading,
  extractUploadImagesLoading,
  extractUploadImagesError
} from '@screens/SpaceEditor/reducers';
import {
  chooseSpaceRoutine,
  fetchSpaceDetailsRoutine,
  fetchSpacesBySpaceTemplateRoutine,
  hideSpaceRoutine,
  saveSpaceTemplateRoutine,
  saveSpaceRoutine,
  changeSpaceBlockedRoutine,
  uploadImagesRoutine,
  deleteImageRoutine,
  reorderImagesRoutine
} from '@screens/SpaceEditor/routines';
import { ISaveRequest } from '@screens/SpaceEditor/containers/SpaceEditorPage/sagas';
import { ISpaceDto } from '@screens/SpaceEditor/models/ISpaceDto';
import { ISpaceEdit } from '@screens/SpaceEditor/models/ISpaceEdit';
import SpacesEditingForm, { ISpacesEditingFormProps } from '@components/NewDesign/Spaces/SpacesEditingForm';
import SpacesTable from '@components/NewDesign/Spaces/SpacesTable';
import { extractCreatedSpaceTemplateId } from '@screens/BuildingEditor/reducers';
import { clearCreatedSpaceTemplateIdRoutine } from '@screens/BuildingEditor/routines';
import { IChangeSpaceBlocked } from '@screens/SpaceEditor/models/IChangeSpaceBlocked';
import ImageManager from '@screens/BuildingEditor/components/ImageManager';
import { IUploadSpaceTemplateImagesRequest } from '@screens/SpaceEditor/models/IUploadSpaceTemplateImagesRequest';
import { IReorderSpaceTemplateImagesRequest } from '@screens/SpaceEditor/models/IReorderSpaceTemplateImagesRequest';
import { IDeleteSpaceTemplateImageRequest } from '@screens/SpaceEditor/models/IDeleteSpaceTemplateImageRequest';
import styles from './styles.module.scss';

export interface ISpacesManagementPageProps extends IState, IActions, ISpacesEditingFormProps {
}

interface IState {
  spaceTemplateLoading: boolean;
  spaceTemplate: ISpaceTemplateDto;
  spacesLoading: boolean;
  spaces: ISpaceDto[];
  chosenSpace?: Partial<ISpaceDto>;
  saveSpaceLoading: boolean;
  createdSpaceTemplateId: string | undefined;
  isImagesUploading: boolean;
  imagesUploadError: string;
}

interface IActions {
  changeSpaceBlocked: IBindingCallback1<IChangeSpaceBlocked>;
  saveSpace: IBindingCallback1<ISpaceEdit>;
  saveTemplateSpace: IBindingCallback1<ISaveRequest>;
  loadSpaceTemplate: IBindingCallback1<string>;
  loadSpaces: IBindingCallback1<string>;
  chooseSpace: IBindingCallback1<Partial<ISpaceDto>>;
  hideSpace: IBindingAction;
  clearCreatedSpaceTemplateId: IBindingAction;
  onUploadImages: IBindingCallback1<IUploadSpaceTemplateImagesRequest>;
  onReorderImages: IBindingCallback1<IReorderSpaceTemplateImagesRequest>;
  onDeleteImage: IBindingCallback1<IDeleteSpaceTemplateImageRequest>;
}

const SpacesManagementPage: React.FC<ISpacesManagementPageProps> = (
  { saveLoading, loadSpaces,
    spaces, spacesLoading, saveSpaceLoading, saveSpaceTemplate, saveSpace,
    chooseSpace, hideSpace, chosenSpace, loadingValues, initialData,
    createdSpaceTemplateId, clearCreatedSpaceTemplateId, spaceTemplate,
    changeSpaceBlocked, isImagesUploading, imagesUploadError, onUploadImages, onReorderImages, onDeleteImage
  }
) => {
  useEffect(() => {
    if (initialData !== undefined) {
      loadSpaces(initialData?.id);
    } else if (createdSpaceTemplateId) {
      loadSpaces(createdSpaceTemplateId);
      clearCreatedSpaceTemplateId();
    }
  }, [clearCreatedSpaceTemplateId, createdSpaceTemplateId, initialData, loadSpaces]);

  return (
    <div className={styles.container}>
      <SpacesEditingForm
        isSpaceTemplateLoaded={!!spaceTemplate}
        saveSpaceTemplate={saveSpaceTemplate}
        initialData={initialData}
        loadingValues={loadingValues}
        saveLoading={saveLoading}
      />
      {!!spaceTemplate?.id && (
        <ImageManager
          images={spaceTemplate?.images || []}
          uploading={isImagesUploading}
          uploadImages={formData => onUploadImages({ id: spaceTemplate?.id, formData })}
          deleteImage={imageId => onDeleteImage({ spaceTemplateId: spaceTemplate?.id, imageId })}
          onReorderImages={payload => onReorderImages({ ...payload, id: spaceTemplate?.id })}
          uploadError={imagesUploadError}
        />
      )}
      { !!spaceTemplate && (
        <>
          <Divider />
          <SpacesTable
            spaces={spaces}
            saveSpace={saveSpace}
            changeSpaceBlocked={changeSpaceBlocked}
            chooseSpace={chooseSpace}
            hideSpace={hideSpace}
            chosenSpace={chosenSpace}
            saveSpaceLoading={saveSpaceLoading}
            spacesLoading={spacesLoading}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps: (state) => IState = state => ({
  spaceTemplateLoading: extractFetchSpaceDetailsLoading(state),
  spaceTemplate: extractSpaceTemplate(state),
  spacesLoading: extractFetchSpacesBySpaceTemplateLoading(state),
  spaces: extractSpaces(state),
  chosenSpace: extractChosenSpace(state),
  saveSpaceLoading: extractSaveSpaceLoading(state),
  createdSpaceTemplateId: extractCreatedSpaceTemplateId(state),
  isImagesUploading: extractUploadImagesLoading(state),
  imagesUploadError: extractUploadImagesError(state)
});

const mapDispatchToProps: IActions = {
  saveSpace: saveSpaceRoutine,
  changeSpaceBlocked: changeSpaceBlockedRoutine,
  loadSpaceTemplate: fetchSpaceDetailsRoutine,
  loadSpaces: fetchSpacesBySpaceTemplateRoutine,
  saveTemplateSpace: saveSpaceTemplateRoutine,
  chooseSpace: chooseSpaceRoutine.fulfill,
  hideSpace: hideSpaceRoutine.fulfill,
  clearCreatedSpaceTemplateId: clearCreatedSpaceTemplateIdRoutine.fulfill,
  onUploadImages: uploadImagesRoutine,
  onDeleteImage: deleteImageRoutine,
  onReorderImages: reorderImagesRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(SpacesManagementPage);
