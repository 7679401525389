import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { times, isEmpty } from 'lodash';
import { Placeholder, PlaceholderHeader, PlaceholderLine } from 'semantic-ui-react';
import { ISpaceWithAvailability } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import SpaceItem from '@screens/BuildingDetails/components/SpaceItem';
import { IDatesData } from '@screens/BrowseSpaces/model/QueryData';
import DatePickerDropdown from '@components/DatePickerDropdown';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { ISpacesAvailabilityRequest } from '@screens/BuildingDetails/model/SpacesAvailabilityRequest';
import { IBookingRequest, ISpaceToAmount } from '@screens/BuildingDetails/model/BookingRequest';
import { BillingPeriod } from '@models/domain/BillingPeriod';
import { IBriefBookingDto } from '@models/domain/Booking';
import { ISpaceBooked } from '@screens/BookingCheckout/model/BookingCheckout';
import { ICurrentBooking } from '@screens/BuildingDetails/model/ICurrentBooking';
import { extractCurrentUser, extractUserRoles } from '@screens/Authorization/reducers';
import { Role } from '@screens/Authorization/models/Roles';
import SubHeader3 from '@components/NewDesign/Typography/SubHeaders/SubHeader3';
import OtherNeedsNotes from '@screens/BookingCheckout/components/BookingDetails/OtherNeedsNotes';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import { toPrice } from '@helpers/price.helper';
import { Currency } from '@models/domain/Currency';
import Caption1 from '@components/NewDesign/Typography/Caption/Caption1';
import PopupWrapper from '@components/NewDesign/PopupWrapper';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import { history } from '@helpers/history.helper';
import { ViewImagesModal } from '@components/NewDesign/ViewImagesModal';
import styles from './styles.module.scss';

export interface IBookingSectionProps {
  loading: boolean;
  spaces: ISpaceWithAvailability[];
  selectedDates?: IDatesData;
  fetchSpaceAvailability: IBindingCallback1<ISpacesAvailabilityRequest>;
  spacesLoading: boolean;
  requestBooking: IBindingCallback1<IBookingRequest>;
  redirectBooking?: IBindingCallback1<ICurrentBooking>;
  currentBooking?: ICurrentBooking;
  clearCurrentBooking?: IBindingAction;
  bookingLoading: boolean;
  className?: string;
  isNeedToResetSelectedSpaces?: boolean;
  toggleResetSelectedSpaces?: IBindingAction;
}

export interface ILocationState {
  booking?: IBriefBookingDto;
  spaces?: ISpaceBooked[];
}

const BookingSection: React.FC<IBookingSectionProps> = (
  {
    loading, spaces, selectedDates: searchSelectedDates,
    fetchSpaceAvailability, spacesLoading, requestBooking, redirectBooking, currentBooking,
    clearCurrentBooking, bookingLoading, className, isNeedToResetSelectedSpaces,
    toggleResetSelectedSpaces
  }
) => {
  const [selectedSpaces, setSelectedSpaces] = useState<ISpaceToAmount[]>([]);
  const [selectedDates, setSelectedDates] = useState(searchSelectedDates || { startingDate: new Date() });
  const [notesText, setNotesText] = useState<string>('');
  const [expandedSpaceImages, setExpandedSpaceImages] = useState<ISpaceWithAvailability | null>(null);
  const user = useSelector(extractCurrentUser);
  const role = useSelector(extractUserRoles)[0];
  const location = useLocation<ILocationState>();
  const { state } = location;

  useEffect(() => {
    if (isNeedToResetSelectedSpaces) {
      setSelectedSpaces([]);
      toggleResetSelectedSpaces();
    }
  }, [isNeedToResetSelectedSpaces, toggleResetSelectedSpaces]);

  useEffect(() => {
    if (currentBooking && currentBooking.pathname === location.pathname) {
      setSelectedDates(currentBooking.selectedDates);
      setSelectedSpaces(currentBooking.selectedSpaces);
      setNotesText(currentBooking.note);
      clearCurrentBooking();
    }
  }, [clearCurrentBooking, currentBooking, location.pathname]);

  useEffect(() => {
    if (state?.booking) {
      setSelectedDates(prevState => ({
        ...prevState,
        startingDate: new Date(state.booking.startingDate),
        endingDate: state.booking.endingDate ? new Date(state.booking.endingDate) : null
      }));
      setNotesText(state.booking.note);
    }
  }, [location, state]);

  useEffect(() => {
    if (state?.spaces) {
      setSelectedSpaces(state.spaces
        .map(space => ({ amount: space.amountBooked, spaceTemplateId: space.id })));
    }
  }, [location, state]);

  const handleSpaceItemClick = (spaceModel: ISpaceToAmount) => {
    setSelectedSpaces(prev => [
      ...prev.filter(selSp => selSp.spaceTemplateId !== spaceModel.spaceTemplateId),
      spaceModel
    ]);
  };

  const handleDatesChange = (dates: IDatesData) => {
    setSelectedDates(dates);
    setSelectedSpaces([]);
    fetchSpaceAvailability({ spaceIds: spaces.map(sp => sp.id), dates });
  };

  const redirectBookingRequest = () => {
    redirectBooking({
      pathname: location.pathname,
      selectedSpaces,
      selectedDates,
      note: notesText
    });
  };

  const makeBookingRequest = () => {
    requestBooking({
      billingPeriod: BillingPeriod.MONTHLY,
      dates: selectedDates,
      note: notesText,
      spacesToAmount: selectedSpaces
    });
  };

  const handleBookClick = () => {
    if (isEmpty(user)) {
      redirectBookingRequest();
    } else if (!isEmpty(user) && !role) {
      history.push(ENDPOINTS.USER);
    } else {
      makeBookingRequest();
    }
  };

  const bookingDisabled = useCallback(() => {
    if (selectedSpaces.length === 0) return true;
    return selectedSpaces.map(sp => sp.amount)
      .reduce((prev, curr) => prev + curr) === 0;
  },
  [selectedSpaces]);

  const bookingAllowed = !role || role === Role.NEED;

  const total = selectedSpaces
    .map(s => s.amount * spaces.find(st => s.spaceTemplateId === st.id).pricePerMonth)
    .reduce((a, b) => a + b, 0);

  return (
    <div className={className}>
      <ViewImagesModal space={expandedSpaceImages} onClose={() => setExpandedSpaceImages(null)} />
      <div className={styles.datesWrapper}>
        <SubHeader3 className={styles.datesLabel}>
          Showing spaces available
          {selectedDates?.endingDate ? ' during' : ' starting'}
        </SubHeader3>
        <div>
          <DatePickerDropdown
            value={selectedDates}
            onChange={handleDatesChange}
          />
        </div>
      </div>
      {selectedDates.startingDate && (
        <>
          {loading || spacesLoading ? (
            <>
              {times(2, i => (
                <Placeholder key={i}>
                  <PlaceholderHeader image>
                    <PlaceholderLine />
                    <PlaceholderLine />
                  </PlaceholderHeader>
                  <Placeholder.Paragraph>
                    <PlaceholderLine length="medium" />
                    <PlaceholderLine length="short" />
                  </Placeholder.Paragraph>
                </Placeholder>
              ))}
            </>
          ) : (
            <div className={styles.spaces_list}>
              {spaces.map(sp => (
                <SpaceItem
                  key={sp.id}
                  space={sp}
                  amountSelected={selectedSpaces.find(selSp => (selSp.spaceTemplateId === sp.id))?.amount || 0}
                  initialAmount={selectedSpaces.find(selSp => (selSp.spaceTemplateId === sp.id))?.amount || 0}
                  onClick={handleSpaceItemClick}
                  showLocalSum
                  hideCounterInput={!bookingAllowed}
                  onViewImagesClick={space => setExpandedSpaceImages(space)}
                />
              ))}
            </div>
          )}
        </>
      )}
      { bookingAllowed && (
        <>
          <OtherNeedsNotes notesText={notesText} setNotesText={setNotesText} />
          <PopupWrapper
            trigger={(
              <div className={styles.bookWrapper}>
                <Caption1 className={styles.total}>
                  Total:
                  {' '}
                  {toPrice({
                    amount: total,
                    currency: Currency.USD
                  })}
                  {' '}
                  Monthly
                </Caption1>
                <PrimaryButton
                  className={styles.book_btn}
                  disabled={loading || bookingDisabled()}
                  onClick={handleBookClick}
                  loading={bookingLoading}
                >
                  Book
                </PrimaryButton>
              </div>
            )}
            content="Need to select at least one space"
            disabled={!bookingDisabled()}
            closeOnTriggerClick={false}
            position="bottom right"
          />
        </>
      )}
    </div>
  );
};

export default BookingSection;
