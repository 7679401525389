import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { IBookingDetailsForSchedulingDto } from '@screens/NeedsDashboard/Bookings/model/BookingDetailsDto';
import BookingsTable from '@screens/NeedsDashboard/Bookings/containers/BookingsTable';
import { BookingStatus } from '@models/domain/BookingStatus';
import {
  fetchBookingsForNeedRoutine, setCurrentPageRoutine
} from '@screens/NeedsDashboard/Bookings/routines';
import {
  extractCurrentPage,
  extractPageSize
} from '@screens/NeedsDashboard/Bookings/reducers';
import { history } from '@helpers/history.helper';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';
import { FiltersBar } from '@screens/NeedsDashboard/Bookings/containers/FiltersBar';
import { useQueryParams } from '@root/hooks/use-query-params';
import { DEFAULT_PAGE_NUMBER } from '@models/domain/PageableReducerState';
import { NEEDS_DASHBOARD_ENDPOINTS } from '@screens/NeedsDashboard/Root/components/Routing/endpoints';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import { getBookedSpacesQty, getBookedSpacesDetails } from '@helpers/bookings.helper';
import { BookingStatusColumn } from '@components/NewDesign/BookingStatusColumn';
import { BuildingNameColumn } from '@components/NewDesign/BuildingNameColumn';
import styles from './styles.module.scss';

const initialFilters = {
  buildingName: '',
  status: [BookingStatus.PENDING, BookingStatus.CONFIRMED]
};

const isDisabled = (b: IBookingDetailsForSchedulingDto) => b.booking.status === BookingStatus.CANCELLED;

const DropOffAndPickUp = () => {
  const dispatch = useDispatch();
  const page = useSelector(extractCurrentPage);
  const size = useSelector(extractPageSize);
  const [filters, updateFilters] = useQueryParams(initialFilters);
  const filtersWithPageParams = useMemo(() => ({
    ...filters,
    page,
    size
  }), [filters, page, size]);
  const latestPageNumberRef = useRef(page);
  const previousFiltersRef = useRef(null);

  useEffect(() => {
    if (_.isEqual(filtersWithPageParams, previousFiltersRef.current)) {
      return;
    }
    const pageNumber = filtersWithPageParams.page;
    const payload = { ...filtersWithPageParams };
    const isPageShouldBeCleared = pageNumber !== DEFAULT_PAGE_NUMBER
      && pageNumber === latestPageNumberRef.current;

    if (isPageShouldBeCleared) {
      dispatch(setCurrentPageRoutine.fulfill(DEFAULT_PAGE_NUMBER));
      payload.page = DEFAULT_PAGE_NUMBER;
    }

    dispatch(fetchBookingsForNeedRoutine.trigger(payload));
    latestPageNumberRef.current = payload.page;
    previousFiltersRef.current = payload;
  }, [dispatch, filtersWithPageParams]);

  const handleRowClick = useCallback(bookingId => () => {
    history.push(NEEDS_DASHBOARD_ENDPOINTS.BOOKING_DETAILS(bookingId));
  }, []);

  return (
    <>
      <DashboardPageWrapper title="Bookings">
        <DashboardSectionWrapper>
          <FiltersBar filters={filters} onUpdateFilters={updateFilters} />
          <BookingsTable
            tableProps={bookings => ({
              titles: ['Building Name', 'Space Details', 'Qty', 'Status', ''],
              rows: bookings.map(b => ({
                id: b.booking.id,
                cells: [
                  { content: <BuildingNameColumn building={b.building} /> },
                  { content: getBookedSpacesDetails(b.templatesToAmounts) },
                  { content: getBookedSpacesQty(b.templatesToAmounts) },
                  { content: <BookingStatusColumn booking={b.booking} /> },
                  { content: <SchedulingLink booking={b} /> }
                ],
                rowProps: {
                  onClick: handleRowClick(b.booking.id),
                  className: isDisabled(b) && styles.disabled
                }
              }))
            })}
          />
        </DashboardSectionWrapper>
      </DashboardPageWrapper>
    </>
  );
};

const SchedulingLink = ({ booking }) => {
  const handleSchedulingClick = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    const sectionId = 'drop-offs-and-pick-ups-section';
    history.push(`${NEEDS_DASHBOARD_ENDPOINTS.BOOKING_DETAILS(booking.booking.id)}#${sectionId}`);
  }, [booking.booking.id]);

  return (
    <GoToButton
      to=""
      onClick={handleSchedulingClick}
      className={styles.open_scheduling_button}
    >
    Schedule drop-offs/pick-ups
    </GoToButton>
  );
};

export default DropOffAndPickUp;
