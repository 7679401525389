import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { ISpaceTemplateDto } from '@screens/BookingCheckout/model/PaymentRequirementResponse';
import {
  chooseSpaceRoutine,
  fetchSpaceDetailsRoutine,
  fetchSpacesBySpaceTemplateRoutine,
  hideSpaceRoutine,
  saveSpaceTemplateRoutine,
  saveSpaceRoutine,
  clearSpacesRoutine,
  bulkCreateSpacesRoutine,
  deleteImageRoutine,
  reorderImagesRoutine,
  uploadImagesRoutine
} from '@screens/SpaceEditor/routines';
import { ISpaceDto } from '@screens/SpaceEditor/models/ISpaceDto';
import { IDeleteSpaceTemplateImageRequest } from '@screens/SpaceEditor/models/IDeleteSpaceTemplateImageRequest';
import { IImageDto } from '@screens/BuildingDetails/model/BuildingDetailsResponse';

export interface ISpaceEditorReducerState {
  spaceTemplate: ISpaceTemplateDto;
  spaces?: ISpaceDto[];
  chosenSpace?: Partial<ISpaceDto>;
}

const initialState: ISpaceEditorReducerState = {
  spaceTemplate: undefined
};

export const spaceEditorReducer = createReducer(initialState, {
  [fetchSpaceDetailsRoutine.SUCCESS]: (state, { payload }: PayloadAction<ISpaceTemplateDto>) => {
    state.spaceTemplate = payload;
  },
  [fetchSpacesBySpaceTemplateRoutine.SUCCESS]: (state, { payload }: PayloadAction<ISpaceDto[]>) => {
    state.spaces = payload;
  },
  [saveSpaceTemplateRoutine.SUCCESS]: (state, { payload }: PayloadAction<ISpaceTemplateDto>) => {
    state.spaceTemplate = payload;
  },
  [saveSpaceRoutine.SUCCESS]: (state, { payload }: PayloadAction<ISpaceDto>) => {
    const index = state.spaces.findIndex(s => s.id === payload.id);
    if (index !== -1) {
      state.spaces[index] = payload;
    } else {
      state.spaces = [payload, ...state.spaces];
    }
  },
  [bulkCreateSpacesRoutine.SUCCESS]: (state, { payload }: PayloadAction<ISpaceDto[]>) => {
    state.spaces = [...state.spaces, ...payload];
  },
  [chooseSpaceRoutine.FULFILL]: (state, { payload }: PayloadAction<Partial<ISpaceDto>>) => {
    state.chosenSpace = payload;
  },
  [hideSpaceRoutine.FULFILL]: state => {
    state.chosenSpace = undefined;
  },
  [clearSpacesRoutine.FULFILL]: state => {
    state.spaces = undefined;
    state.spaceTemplate = undefined;
  },
  [uploadImagesRoutine.SUCCESS]: (state, { payload }: PayloadAction<IImageDto[]>) => {
    state.spaceTemplate.images = payload;
  },
  [reorderImagesRoutine.SUCCESS]: (state, { payload }: PayloadAction<IImageDto[]>) => {
    state.spaceTemplate.images = payload;
  },
  [deleteImageRoutine.TRIGGER]: (state, { payload: { imageId } }: PayloadAction<IDeleteSpaceTemplateImageRequest>) => {
    state.spaceTemplate.images.find(img => img.id === imageId).deleteLoading = true;
  },
  [deleteImageRoutine.SUCCESS]: (state, { payload: { imageId } }: PayloadAction<IDeleteSpaceTemplateImageRequest>) => {
    const index = state.spaceTemplate.images.findIndex(img => img.id === imageId);
    state.spaceTemplate.images.splice(index, 1);
  },
  [deleteImageRoutine.FAILURE]: (state, { payload: { imageId } }: PayloadAction<IDeleteSpaceTemplateImageRequest>) => {
    state.spaceTemplate.images.find(img => img.id === imageId).deleteLoading = false;
  }
});
