import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { ISpaceTemplateDto } from '@screens/BookingCheckout/model/PaymentRequirementResponse';
import {
  extractChosenSpace,
  extractFetchSpaceDetailsLoading, extractFetchSpacesBySpaceTemplateLoading,
  extractSaveSpaceTemplateLoading, extractSpaces,
  extractSpaceTemplate, extractSaveSpaceLoading
} from '@screens/SpaceEditor/reducers';
import {
  chooseSpaceRoutine,
  fetchSpaceDetailsRoutine,
  hideSpaceRoutine,
  saveSpaceTemplateRoutine, saveSpaceRoutine, clearSpacesRoutine
} from '@screens/SpaceEditor/routines';
import { ISaveRequest } from '@screens/SpaceEditor/containers/SpaceEditorPage/sagas';
import { ISpaceDto } from '@screens/SpaceEditor/models/ISpaceDto';
import { ISpaceEdit } from '@screens/SpaceEditor/models/ISpaceEdit';
import SpacesManagementPage from '@components/NewDesign/Spaces/SpacesManagementPage';
import { extractBuilding } from '@screens/BuildingEditor/reducers';
import { IBuildingForEditing } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { fetchBuildingDetailsRoutine } from '@screens/BuildingEditor/routines';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import Header3 from '@components/NewDesign/Typography/Headers/Header3';

export interface ISpaceEditorModalProps extends IState, IActions {
  spaceEditor: string;
  setSpaceEditor: any;
}

interface IState {
  saveLoading: boolean;
  spaceTemplateLoading: boolean;
  spaceTemplate: ISpaceTemplateDto;
  building: IBuildingForEditing;
}

interface IActions {
  saveTemplateSpace: IBindingCallback1<ISaveRequest>;
  loadSpaceTemplate: IBindingCallback1<string>;
  saveSpace: IBindingCallback1<ISpaceEdit>;
  chooseSpace: IBindingCallback1<Partial<ISpaceDto>>;
  hideSpace: IBindingAction;
  clearSpaces: IBindingAction;
  loadBuilding: IBindingCallback1<string>;
}

const SpaceEditorModal: React.FC<ISpaceEditorModalProps> = (
  { saveTemplateSpace, spaceTemplate, saveLoading, spaceTemplateLoading,
    spaceEditor, setSpaceEditor, loadSpaceTemplate, clearSpaces, building, loadBuilding
  }
) => {
  useEffect(() => {
    if (spaceEditor !== undefined) {
      loadSpaceTemplate(spaceEditor);
    }
  }, [loadSpaceTemplate, spaceEditor]);

  const handleClose = () => {
    clearSpaces();
    setSpaceEditor(null);
    loadBuilding(building?.id);
  };

  return (
    <>
      <SmallLinkButton
        left
        onClick={handleClose}
        to=""
      >
        Back to spaces
      </SmallLinkButton>
      <Header3 marginTop>Edit space</Header3>
      <SpacesManagementPage
        isSpaceTemplateLoaded={!!spaceTemplate}
        saveSpaceTemplate={data => saveTemplateSpace({ id: spaceEditor, data })}
        initialData={spaceTemplate}
        loadingValues={spaceTemplateLoading}
        saveLoading={saveLoading}
      />
    </>
  );
};

const mapStateToProps: (state) => IState = state => ({
  saveLoading: extractSaveSpaceTemplateLoading(state),
  spaceTemplateLoading: extractFetchSpaceDetailsLoading(state),
  spaceTemplate: extractSpaceTemplate(state),
  spacesLoading: extractFetchSpacesBySpaceTemplateLoading(state),
  spaces: extractSpaces(state),
  chosenSpace: extractChosenSpace(state),
  saveSpaceLoading: extractSaveSpaceLoading(state),
  building: extractBuilding(state)
});

const mapDispatchToProps: IActions = {
  loadSpaceTemplate: fetchSpaceDetailsRoutine,
  saveSpace: saveSpaceRoutine,
  saveTemplateSpace: saveSpaceTemplateRoutine,
  loadBuilding: fetchBuildingDetailsRoutine,
  chooseSpace: chooseSpaceRoutine.fulfill,
  hideSpace: hideSpaceRoutine.fulfill,
  clearSpaces: clearSpacesRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(SpaceEditorModal);
