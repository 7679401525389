import React from 'react';
import BookingStatusLabel from '@components/BookingStatusLabel';
import { IBriefBookingDto } from '@models/domain/Booking';

interface IBookingStatusColumnProps {
  booking: IBriefBookingDto;
}

const BookingStatusColumn = ({ booking }: IBookingStatusColumnProps) => (
  <BookingStatusLabel
    status={booking.status}
    createdAt={booking.createdAt}
  />
);

export { BookingStatusColumn };
