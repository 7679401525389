import React, { useEffect, useState } from 'react';
import ImageUploader from '@screens/BuildingEditor/components/ImageManager/ImageUploader';
import ExpandedImageModal from '@screens/BuildingEditor/components/ImageManager/ExpandedImageModal';
import { IImageDto } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { IBindingCallback1 } from '@models/Callbacks';
import DeleteConfirm from '@screens/BuildingEditor/components/ImageManager/DeleteConfirm';
import { ImageWrapper } from '@screens/BuildingEditor/components/ImageManager/ImageMiniature/Wrapper';
import ImageContainer from '@components/ImageContainer';
import { ImagesArea } from '@screens/BuildingEditor/components/ImageManager/ImagesArea';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import TransparentButton from '@components/NewDesign/Button/TransparentButton';
import { IImageIndexDto } from '@screens/BuildingEditor/model/BuildingImageIndexesRequest';
import styles from './styles.module.scss';

export interface IImagesTabProps {
  images: IImageDto[];
  uploading: boolean;
  uploadImages: IBindingCallback1<FormData>;
  deleteImage: IBindingCallback1<string>;
  uploadError: string;
  setImageAsAvatar?: IBindingCallback1<string>;
  onReorderImages: IBindingCallback1<{ imageIndexes: IImageIndexDto[] }>;
  hideImageButtons?: boolean;
}

const MIN_IMAGES_AMOUNT_TO_REORDER = 2;

const toImageIndexDto = ({ id }: IImageDto, index: number): IImageIndexDto => ({ id, index });

const ImageManager: React.FC<IImagesTabProps> = ({
  images,
  uploadImages,
  uploading,
  deleteImage,
  uploadError,
  setImageAsAvatar,
  onReorderImages
}) => {
  const [orderedImages, setOrderedImages] = useState<IImageDto[]>(images);
  const [expandedImage, setExpandedImage] = useState<string>();
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [pendingDelete, setPendingDelete] = useState<string>();
  const [imagesSrc, setImagesSrc] = useState<string[]>([]);
  const [isReorderEnabled, setIsReorderEnabled] = useState<boolean>(false);

  useEffect(() => {
    setOrderedImages(images);
  }, [images]);

  const handlePreviewImages = () => {
    setImagesSrc([]);
    selectedImages.forEach(file => {
      const reader = new FileReader();
      reader.onload = (event: any) => setImagesSrc(prev => [...prev, event.target.result]
        .filter((val, i, arr) => arr.indexOf(val) === i));
      reader.readAsDataURL(file);
    });
  };

  const handleEnableReorder = () => {
    setIsReorderEnabled(true);
  };

  const handleSaveOrder = () => {
    setIsReorderEnabled(false);
    if (orderedImages?.length) {
      onReorderImages({
        imageIndexes: orderedImages.map(toImageIndexDto)
      });
    }
  };
  const isUploadFormOpen = selectedImages?.length > 0;
  const isEnoughImagesToReorder = orderedImages?.length >= MIN_IMAGES_AMOUNT_TO_REORDER;
  const ReorderButton = isReorderEnabled ? (
    <PrimaryButton
      content="Save"
      onClick={handleSaveOrder}
      icon="save"
      labelPosition="left"
      className={styles.reorder_button}
    />
  ) : (
    <TransparentButton
      content="Re-order images"
      onClick={handleEnableReorder}
      icon="sort"
      labelPosition="left"
      className={styles.reorder_button}
    />
  );

  return (
    <div className={styles.container}>
      {!isUploadFormOpen && isEnoughImagesToReorder ? ReorderButton : null}
      <DeleteConfirm
        deleteImage={deleteImage}
        pendingDelete={pendingDelete}
        setPendingDelete={setPendingDelete}
      />
      <ExpandedImageModal
        expandedImage={expandedImage}
        setExpandedImage={setExpandedImage}
      />
      {isUploadFormOpen && (
        <>
          <ImageUploader
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            uploading={uploading}
            previewImages={handlePreviewImages}
            uploadImages={uploadImages}
            uploadError={uploadError}
            images={orderedImages}
          />
          { !!imagesSrc.length && (
            <div className={styles.prevImages}>
                {Array.isArray(imagesSrc) && imagesSrc.length
                  ? imagesSrc.map((s, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ImageWrapper key={i}>
                      <ImageContainer className={styles.image} src={s} />
                    </ImageWrapper>
                  ))
                  : null}
            </div>
          )}
        </>
      )}
      <ImagesArea
        images={orderedImages}
        onImagesOrderChange={setOrderedImages}
        setExpandedImage={setExpandedImage}
        setImageAsAvatar={setImageAsAvatar}
        setPendingDelete={setPendingDelete}
        setSelectedImages={setSelectedImages}
        reorderEnabled={isReorderEnabled}
      />
    </div>
  );
};

export default ImageManager;
