import React from 'react';
import TableImportantContent from '@components/NewDesign/Typography/FormAndTable/TableImportantContent';
import { IBuildingForDisplaying } from '@screens/BuildingDetails/model/BuildingDetailsResponse';

interface IBuildingNameColumnProps {
  building: IBuildingForDisplaying;
}

const BuildingNameColumn = ({ building }: IBuildingNameColumnProps) => (
  <TableImportantContent disableTranslation>
    {building.buildingName}
  </TableImportantContent>
);

export { BuildingNameColumn };
