import React from 'react';
import { IImageDto } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { ImageMiniature } from '@screens/BuildingEditor/components/ImageManager/ImageMiniature';
import { ImagesDragAndDropArea } from '@screens/BuildingEditor/components/ImageManager/ImagesDragAndDropArea';
import AddImageMiniature from '@screens/BuildingEditor/components/ImageManager/AddImageMiniature';
import styles from './styles.module.scss';

type Props = {
  images: IImageDto[];
  onImagesOrderChange: any;
  setExpandedImage: any;
  setPendingDelete: any;
  setImageAsAvatar: any;
  setSelectedImages: any;
  reorderEnabled?: boolean;
}

const ImagesArea = ({
  images,
  onImagesOrderChange,
  setExpandedImage,
  setPendingDelete,
  setImageAsAvatar,
  setSelectedImages,
  reorderEnabled
}: Props) => {
  if (reorderEnabled) {
    return (
      <ImagesDragAndDropArea
        images={images}
        onImagesOrderChange={onImagesOrderChange}
        setPendingDelete={setPendingDelete}
        setImageAsAvatar={setImageAsAvatar}
        setExpandedImage={setExpandedImage}
      />
    );
  }

  const renderImage = i => (
    <ImageMiniature
      id={i.id}
      onClick={setExpandedImage}
      key={i.id}
      src={i.url}
      onDeleteClick={setPendingDelete}
      deleting={i.deleteLoading}
      setAsAvatar={setImageAsAvatar}
      settingAvatar={i.setAsAvatarLoading}
      isAvatar={i.avatar}
    />
  );

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }} className={styles.images}>
      {images.map(renderImage)}
      <AddImageMiniature
        images={images}
        onImagesSelection={setSelectedImages}
      />
    </div>
  );
};

export { ImagesArea };
