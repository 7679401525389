import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import BookingSection from '@screens/BuildingDetails/components/BookingSection';
import { IBuildingForDisplaying, ISpaceWithAvailability } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { IDatesData } from '@screens/BrowseSpaces/model/QueryData';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { ICurrentBooking } from '@screens/BuildingDetails/model/ICurrentBooking';
import { ISpacesAvailabilityRequest } from '@screens/BuildingDetails/model/SpacesAvailabilityRequest';
import { IBookingRequest } from '@screens/BuildingDetails/model/BookingRequest';
import { extractCurrentUser, extractUserRoles } from '@screens/Authorization/reducers';
import { Role } from '@screens/Authorization/models/Roles';
import BuildingTags from './components/BuildingTags';
import ImageGalleryCarousel from '@screens/NeedsDashboard/BuildingDetails/components/ImageGalleryCarousel';
import BuildingHeaderInfo from '@screens/BookingCheckout/components/BookingDetails/BuildingHeaderInfo';
import GoToButton from '@components/NewDesign/Button/GoToButton';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
// import Rating from '@screens/BookingCheckout/components/BookingDetails/Rating';
import s from './styles.module.scss';
import AdditionalServicesInfo from '@screens/BuildingDetails/components/AdditionalServicesInfo';
import { IAdditionalServiceWithAvailability } from '@models/domain/additional_service/AdditionalServiceWithAvailability';
import MetaTags from '@components/MetaTags';

export interface IDescriptionSectionProps {
  className: string;
  loading: boolean;
  previewBuildingName: string;
  building: IBuildingForDisplaying;
  spaces: ISpaceWithAvailability[];
  selectedDates: IDatesData;
  fetchSpaceAvailability: IBindingCallback1<ISpacesAvailabilityRequest>;
  spacesLoading: boolean;
  requestBooking: IBindingCallback1<IBookingRequest>;
  redirectBooking: IBindingCallback1<ICurrentBooking>;
  currentBooking: ICurrentBooking;
  clearCurrentBooking: IBindingAction;
  bookingLoading: boolean;
  toggleLike: IBindingCallback1<string>;
  likeLoading: boolean;
  setWriting: IBindingCallback1<boolean>;
  isNeedToResetSelectedSpaces: boolean;
  toggleResetSelectedSpaces: IBindingAction;
  services: IAdditionalServiceWithAvailability[];
  servicesLoading?: boolean;
}

const DescriptionSection: React.FC<IDescriptionSectionProps> = (
  {
    className, loading, building, spaces, selectedDates,
    fetchSpaceAvailability, spacesLoading, requestBooking, redirectBooking, currentBooking, clearCurrentBooking,
    bookingLoading, toggleLike, likeLoading, setWriting, isNeedToResetSelectedSpaces, toggleResetSelectedSpaces,
    services, servicesLoading
  }
) => {
  const history = useHistory();
  const currentUser = useSelector(extractCurrentUser);
  const role = useSelector(extractUserRoles)[0];

  const handleWriteToOwner = useCallback(() => {
    if (currentUser?.id) {
      setWriting(true);
    } else {
      history.push(`${ENDPOINTS.LOGIN}?redirect=${history.location.pathname.substr(1)}`);
    }
  }, [currentUser, history, setWriting]);

  return (
    <div className={className}>
      <div className={s.top_buttons_container}>
        <SmallLinkButton to="" onClick={() => history.goBack()} left>
          Go Back
        </SmallLinkButton>
      </div>
      {building && (
        <>
          <MetaTags title={building.buildingName} withSuffix description={building.description} />
          <BuildingHeaderInfo building={building} toggleLike={toggleLike} likeLoading={likeLoading} />
          {/* <Rating />*/}
          <BuildingTags loading={loading} building={building} />
          <AdditionalServicesInfo loading={servicesLoading} services={services} />
        </>
      )}
      <ImageGalleryCarousel
        className={s.image_container}
        images={building?.gallery ?? []}
        loading={loading}
        building={building}
      />
      {building && role !== Role.HAVE && (
        <GoToButton to="" onClick={handleWriteToOwner} className={s.contactButton}>
          Contact Host
        </GoToButton>
      )}
      <BookingSection
        loading={loading}
        spaces={spaces}
        selectedDates={selectedDates}
        fetchSpaceAvailability={fetchSpaceAvailability}
        spacesLoading={spacesLoading}
        requestBooking={requestBooking}
        redirectBooking={redirectBooking}
        currentBooking={currentBooking}
        clearCurrentBooking={clearCurrentBooking}
        bookingLoading={bookingLoading}
        isNeedToResetSelectedSpaces={isNeedToResetSelectedSpaces}
        toggleResetSelectedSpaces={toggleResetSelectedSpaces}
      />
    </div>
  );
};

export default DescriptionSection;
