import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ImageMiniature, IImageMiniatureProps } from '@screens/BuildingEditor/components/ImageManager/ImageMiniature';

const SortableImageMiniature: React.FC<IImageMiniatureProps> = (
  { id, ...props }
) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? '0.3' : '1'
  };

  return (
    <ImageMiniature
      id={id}
      ref={setNodeRef}
      style={style}
      {...props}
      {...attributes}
      {...listeners}
    />
  );
};

export { SortableImageMiniature };
