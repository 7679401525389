import { IBriefBookingDto } from '@models/domain/Booking';
import { ISpaceTemplateDto } from '@screens/BookingCheckout/model/PaymentRequirementResponse';
import { IBuildingForDisplaying, ISpaceWithAvailability } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { Currency } from '@models/domain/Currency';

export interface IBookingDetailsForSchedulingDto {
  booking: IBriefBookingDto;
  building: IBuildingForDisplaying;
  templatesToAmounts: ITemplate2Amount[];
  needsReview: boolean;
}

export interface ITemplate2Amount {
  spaces: IShortSpaceInfo[];
  spaceTemplate: ISpaceTemplateDto;
}

export interface IShortSpaceInfo {
  id: string;
  index: string;
}

export const t2aToSpaceWithAvailability: (t2a: ITemplate2Amount) => ISpaceWithAvailability = ({ spaceTemplate, spaces }) => ({
  currency: Currency[spaceTemplate.fullCost.currency],
  spaceType: spaceTemplate.type,
  spaceCategory: spaceTemplate.category,
  amountAvailable: spaces.length,
  spaceIdsAvailable: spaces.map(s => s.id),
  alias: spaceTemplate.alias,
  pricePerDay: spaceTemplate.fullCost.pricePerDay,
  pricePerMonth: spaceTemplate.fullCost.pricePerMonth,
  height: spaceTemplate.height,
  id: spaceTemplate.id,
  length: spaceTemplate.length,
  lengthUnit: spaceTemplate.lengthUnit,
  width: spaceTemplate.width,
  images: spaceTemplate.images
});
