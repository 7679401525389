import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContent } from 'semantic-ui-react';
import moment from 'moment';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import ModalWrapper from '@components/NewDesign/ModalWrapper';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import { IBookingDetailsForSchedulingDto } from '@screens/NeedsDashboard/Bookings/model/BookingDetailsDto';
import { getBookedSpacesDetails, getBookedSpacesQty } from '@helpers/bookings.helper';
import TableWithSchedules from '@screens/NeedsDashboard/Bookings/components/TableWithSchedules';
import { IBindingCallback1 } from '@models/Callbacks';
import { BookingStatusColumn } from '@components/NewDesign/BookingStatusColumn';
import {
  extractBuildingBookingsData,
  extractFetchBuildingBookingsForNeedLoading
} from '@screens/NeedsDashboard/BuildingDetails/reducers';
import { setBookingsTablePageRoutine } from '@screens/NeedsDashboard/BuildingDetails/routines';
import { DATE_FORMAT } from '@helpers/date.helper';
import TableImportantContent from '@components/NewDesign/Typography/FormAndTable/TableImportantContent';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import styles from './styles.module.scss';

interface ISelectBookingButtonWithModalProps {
  selectedBookingId: string;
  onSelectBooking: IBindingCallback1<IBookingDetailsForSchedulingDto>;
}

const SelectBookingButtonWithModal = ({ selectedBookingId, onSelectBooking }: ISelectBookingButtonWithModalProps) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    items: bookings,
    page,
    totalPages
  } = useSelector(extractBuildingBookingsData);
  const isBookingsLoading = useSelector(extractFetchBuildingBookingsForNeedLoading);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleSelectBooking = useCallback(booking => () => {
    onSelectBooking(booking);
    setIsOpen(false);
  }, [onSelectBooking]);

  const handleSetPage = useCallback((currentPage: number) => {
    dispatch(setBookingsTablePageRoutine.fulfill(currentPage));
  }, [dispatch]);

  if (bookings?.length < 2) {
    return null;
  }

  return (
    <>
      <SmallLinkButton
        to=""
        onClick={handleOpenModal}
        className={styles.choose_another_button}
      >
        Choose another
      </SmallLinkButton>
      <ModalWrapper open={isOpen} onClose={handleCloseModal} size="large" closeIcon className={styles.modal_wrapper}>
        <ModalHeaderWrapper>Choose another booking</ModalHeaderWrapper>
        <ModalContent>
          <TableWithSchedules
            bookingsLoading={isBookingsLoading}
            totalPages={totalPages}
            page={page}
            setPage={handleSetPage}
            tableProps={{
              titles: ['From', 'To', 'Space Details', 'Qty', 'Status', ''],
              rows: bookings.map(b => {
                const isActive = b.booking.id === selectedBookingId;
                return {
                  id: b.booking.id,
                  cells: [
                    { content: <DateColumn date={b.booking.startingDate} /> },
                    { content: <DateColumn date={b.booking?.endingDate} /> },
                    { content: getBookedSpacesDetails(b.templatesToAmounts) },
                    { content: getBookedSpacesQty(b.templatesToAmounts) },
                    { content: <BookingStatusColumn booking={b.booking} /> },
                    { content: isActive
                      ? <CurrentLabel />
                      : <SelectButton isActive={isActive} onSelect={handleSelectBooking(b)} /> }
                  ],
                  rowProps: {
                    className: isActive && styles.active
                  }
                };
              })
            }}
          />
        </ModalContent>
      </ModalWrapper>
    </>
  );
};

const DateColumn = ({ date }) => {
  if (!date) {
    return null;
  }
  return (
    <TableImportantContent>{moment(date).format(DATE_FORMAT)}</TableImportantContent>
  );
};

const CurrentLabel = () => (
  <div className={styles.current_label}>
    <Caption2>CURRENT</Caption2>
  </div>
);

const SelectButton = ({ isActive, onSelect }) => (
  <div className={styles.select_button_column}>
    <PrimaryButton
      disabled={isActive}
      onClick={onSelect}
      className={styles.select_button}
    >
      Select
    </PrimaryButton>
  </div>
);

export { SelectBookingButtonWithModal };
