import { callApi } from '@helpers/api.helper';
import { ISpaceTemplateModificationRequest } from '@components/NewDesign/Spaces/SpacesEditingForm';
import { IReorderSpaceTemplateImagesRequest } from '@screens/SpaceEditor/models/IReorderSpaceTemplateImagesRequest';
import { IUploadSpaceTemplateImagesRequest } from '@screens/SpaceEditor/models/IUploadSpaceTemplateImagesRequest';
import { IDeleteSpaceTemplateImageRequest } from '@screens/SpaceEditor/models/IDeleteSpaceTemplateImageRequest';

const templatesService = {
  fetchSpaceTemplateDetails: (id: string) => callApi({
    method: 'GET',
    endpoint: `space_templates/${id}`
  }),
  updateSpaceTemplate: (id: string, data: ISpaceTemplateModificationRequest) => callApi({
    method: 'PATCH',
    endpoint: `space_templates/edit/${id}`,
    requestData: data
  }),
  uploadImages: ({ id, formData }: IUploadSpaceTemplateImagesRequest) => callApi({
    method: 'POST',
    endpoint: `space_templates/${id}/images/upload`,
    formData
  }),
  reorderImages: ({ id, imageIndexes }: IReorderSpaceTemplateImagesRequest) => callApi({
    method: 'PATCH',
    endpoint: `space_templates/${id}/images/reorder`,
    requestData: imageIndexes
  }),
  deleteImage: ({ spaceTemplateId, imageId }: IDeleteSpaceTemplateImageRequest) => callApi({
    method: 'DELETE',
    endpoint: `space_templates/${spaceTemplateId}/image/${imageId}`
  })
};

export default templatesService;
