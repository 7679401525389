import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import Tabs from '@components/Tabs';
import { SpacesSectionWrapper } from './components/SpacesSectionWrapper';
import { AdditionalServicesSectionWrapper } from './components/AdditionalServicesSectionWrapper';
import {
  fetchBuildingBookingsForNeedRoutine,
  resetSpacesAndServicesSectionRoutine
} from '@screens/NeedsDashboard/BuildingDetails/routines';
import { BookingStatus } from '@models/domain/BookingStatus';
import {
  extractBuildingBookingsData
} from '@screens/NeedsDashboard/BuildingDetails/reducers';
import { useScrollToElement } from '@root/hooks/use-scroll-to-element';
import styles from './styles.module.scss';

interface ISpacesAndServicesSectionProps {
  buildingId: string;
}

const SpacesAndServicesSection = ({ buildingId }: ISpacesAndServicesSectionProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { tab } = parse(location.search);
  useScrollToElement();

  const {
    items,
    page,
    pageSize
  } = useSelector(extractBuildingBookingsData);

  useEffect(() => {
    if (buildingId) {
      dispatch(fetchBuildingBookingsForNeedRoutine.trigger({
        buildingId,
        status: [BookingStatus.CONFIRMED],
        page,
        size: pageSize
      }));
    }
  }, [buildingId, dispatch, page, pageSize]);

  useEffect(() => () => {
    dispatch(resetSpacesAndServicesSectionRoutine.fulfill());
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []); // ! leave this deps empty

  const isShowOnlySpacesSection = items.length === 0;

  return (
    <div id="booking-section">
      {isShowOnlySpacesSection ? <SpacesSectionWrapper /> : (
        <Tabs
          initialIndex={tab === 'services' ? 1 : 0}
          className={styles.tabs}
          content={[
            {
              key: 1,
              name: 'Available Spaces',
              element: { Element: SpacesSectionWrapper }
            },
            {
              key: 2,
              name: 'Additional Services',
              element: { Element: AdditionalServicesSectionWrapper }
            }
          ]}
          wrapper={{
            Element: Container
          }}
        />
      )}
    </div>
  );
};

export { SpacesAndServicesSection };
