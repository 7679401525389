import React, { useState } from 'react';
import { ModalContent } from 'semantic-ui-react';
import { ImageMiniature } from '@screens/BuildingEditor/components/ImageManager/ImageMiniature';
import ExpandedImageModal from '@screens/BuildingEditor/components/ImageManager/ExpandedImageModal';
import { ISpaceWithAvailability } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import ModalWrapper from '@components/NewDesign/ModalWrapper';
import { IBindingAction } from '@models/Callbacks';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import styles from './styles.module.scss';

interface IViewImageModalProps {
  space: ISpaceWithAvailability;
  onClose: IBindingAction;
}

const ViewImagesModal = ({ space, onClose }: IViewImageModalProps) => {
  const [expandedImage, setExpandedImage] = useState<string>();

  const renderImage = i => (
    <ImageMiniature
      id={i.id}
      onClick={setExpandedImage}
      key={i.id}
      src={i.url}
      isAvatar={i.avatar}
      hideButtons
    />
  );

  if (!space) {
    return null;
  }

  return (
    <ModalWrapper open={!!space?.images?.length} onClose={onClose} size="large" closeIcon>
      <ModalHeaderWrapper>
        {space.alias}
      </ModalHeaderWrapper>
      <ModalContent>
        <ExpandedImageModal
          expandedImage={expandedImage}
          setExpandedImage={setExpandedImage}
        />
        <div className={styles.images_container}>
          {space?.images?.map(renderImage)}
        </div>
      </ModalContent>
    </ModalWrapper>
  );
};

export { ViewImagesModal };
