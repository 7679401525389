import React, { useState } from 'react';
import { ISpaceTemplateDto } from '@screens/BookingCheckout/model/PaymentRequirementResponse';
import SpaceTemplateItem from '@screens/BuildingEditor/components/SpacesEditingTab/SpaceTemplateItem';
import AdditiveListView from '@components/AdditiveListView';
import SpaceCreationModal from '@screens/BuildingEditor/components/SpacesEditingTab/SpaceCreationModal';
import { IBindingCallback1 } from '@models/Callbacks';
import SpaceEditorModal from '@components/NewDesign/Spaces/SpaceEditorModal';
import { ISpaceTemplateModificationRequest } from '@components/NewDesign/Spaces/SpacesEditingForm';

export interface ISpacesEditingTabProps {
  spaceTemplates: ISpaceTemplateDto[];
  loading: boolean;
  createSpace: IBindingCallback1<ISpaceTemplateModificationRequest>;
  spaceCreationLoading: boolean;
}

const SpacesEditingTab: React.FC<ISpacesEditingTabProps> = (
  { spaceTemplates, loading, spaceCreationLoading, createSpace }
) => {
  const [spaceEditor, setSpaceEditor] = useState<string>(null);
  const [isCreateSpaceOpen, setIsCreateSpaceOpen] = useState<boolean>(false);

  if (spaceEditor) {
    return (
      <>
        <SpaceEditorModal
          spaceEditor={spaceEditor}
          setSpaceEditor={setSpaceEditor}
        />
      </>
    );
  }

  if (isCreateSpaceOpen) {
    return (
      <SpaceCreationModal
        onClose={() => setIsCreateSpaceOpen(false)}
        createSpace={createSpace}
        creationLoading={spaceCreationLoading}
      />
    );
  }

  return (
    <>
      <AdditiveListView
        loading={loading}
        items={spaceTemplates}
        onAddClick={() => {
          setIsCreateSpaceOpen(true);
        }}
        renderItem={st => <SpaceTemplateItem spaceTemplate={st} />}
        onItemClick={st => setSpaceEditor(st.id)}
        placeholderText="No spaces created"
      />
    </>
  );
};

export default SpacesEditingTab;
