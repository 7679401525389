import React from 'react';
import { connect } from 'react-redux';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import SpacesManagementPage from '@components/NewDesign/Spaces/SpacesManagementPage';
import {
  extractChosenSpace,
  extractFetchSpaceDetailsLoading, extractFetchSpacesBySpaceTemplateLoading, extractSaveSpaceLoading,
  extractSaveSpaceTemplateLoading, extractSpaces,
  extractSpaceTemplate
} from '@screens/SpaceEditor/reducers';
import {
  clearSpacesRoutine,
  fetchSpaceDetailsRoutine,
  saveSpaceRoutine, saveSpaceTemplateRoutine
} from '@screens/SpaceEditor/routines';
import { ISpaceTemplateDto } from '@screens/BookingCheckout/model/PaymentRequirementResponse';
import { ISaveRequest } from '@screens/SpaceEditor/containers/SpaceEditorPage/sagas';
import { ISpaceEdit } from '@screens/SpaceEditor/models/ISpaceEdit';
import { fetchBuildingDetailsRoutine } from '@screens/BuildingEditor/routines';
import { extractBuilding } from '@screens/BuildingEditor/reducers';
import { IBuildingForEditing } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { ISpaceTemplateModificationRequest } from '@components/NewDesign/Spaces/SpacesEditingForm';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import Header3 from '@components/NewDesign/Typography/Headers/Header3';

interface IState {
  saveLoading: boolean;
  spaceTemplateLoading: boolean;
  spaceTemplate: ISpaceTemplateDto;
  building: IBuildingForEditing;
}

interface IActions {
  saveTemplateSpace: IBindingCallback1<ISaveRequest>;
  loadSpaceTemplate: IBindingCallback1<string>;
  saveSpace: IBindingCallback1<ISpaceEdit>;
  clearSpaces: IBindingAction;
  loadBuilding: IBindingCallback1<string>;
}

export interface ISpaceCreationModalProps extends IState, IActions {
  onClose?: IBindingAction;
  createSpace: IBindingCallback1<ISpaceTemplateModificationRequest>;
  creationLoading: boolean;
}

const SpaceCreationModal: React.FC<ISpaceCreationModalProps> = (
  { onClose, creationLoading, createSpace,
    spaceTemplate, saveTemplateSpace, clearSpaces, building, loadBuilding
  }
) => {
  const saveTemplate = data => saveTemplateSpace({ id: spaceTemplate?.id, data });

  const handleClose = () => {
    clearSpaces();
    loadBuilding(building?.id);
    onClose();
  };

  return (
    <>
      <SmallLinkButton
        left
        onClick={handleClose}
        to=""
      >
        Back to spaces
      </SmallLinkButton>
      <Header3 marginTop>{spaceTemplate ? 'Edit space' : 'Create new space'}</Header3>
      <SpacesManagementPage
        isSpaceTemplateLoaded={!!spaceTemplate}
        saveLoading={creationLoading}
        loadingValues={false}
        saveSpaceTemplate={spaceTemplate ? saveTemplate : createSpace}
        initialData={undefined}
      />
    </>
  );
};

const mapStateToProps: (state) => IState = state => ({
  saveLoading: extractSaveSpaceTemplateLoading(state),
  spaceTemplateLoading: extractFetchSpaceDetailsLoading(state),
  spaceTemplate: extractSpaceTemplate(state),
  spacesLoading: extractFetchSpacesBySpaceTemplateLoading(state),
  spaces: extractSpaces(state),
  chosenSpace: extractChosenSpace(state),
  saveSpaceLoading: extractSaveSpaceLoading(state),
  building: extractBuilding(state)
});

const mapDispatchToProps: IActions = {
  loadSpaceTemplate: fetchSpaceDetailsRoutine,
  saveSpace: saveSpaceRoutine,
  saveTemplateSpace: saveSpaceTemplateRoutine,
  loadBuilding: fetchBuildingDetailsRoutine,
  clearSpaces: clearSpacesRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(SpaceCreationModal);

